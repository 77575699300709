import { $themeBreakpoints } from '@themeConfig'
import { resetPassword } from '@userApi';

export default {
  namespaced: true,
  state: {
    isMarket: true,
    socketURL: process.env.VUE_APP_SOCKET_URL,
    fromLogin: false,
    windowWidth: 0,
    socketAPIURL: process.env.VUE_APP_SOCKET_API_URL,
    storeSelected: 0,
    herculesURL: process.env.VUE_APP_MIDDLEWARE_HERCULES_URL,
    middlewareURL: process.env.VUE_APP_MIDDLEWARE_API_URL,
    middlewareAWSURL: process.env.VUE_APP_MIDDLEWARE_AWS_API_URL,
    middlewareMassImagesURL: process.env.VUE_APP_MIDDLEWARE_MASS_IMAGES_URL,
    GOOGLE_MAPS_HOST: "https://maps.googleapis.com",
    GOOGLE_GEOCODING_PATH: "/maps/api/geocode/json",
    GOOGLE_API_KEY: "AIzaSyBpV3k6fuMQyWOt5mcqNyMqgeQ1L5Rwqa0",
    nodeMiddleware: process.env.VUE_APP_NODE_MIDDLEWARE_API_URL,
    lastSocketOrder: {},
    lastSocketAlert: {},
    UrlMiddlewareGET: process.env.VUE_APP_MIDDLEWARE_GET_API_URL,
    shallShowOverlay: false,
    hasStoresChanged: false,
    warehouseSelected: 0,
    middlewarePromoPOST: process.env.VUE_APP_MIDDLEWARE_PROMO_POST_API_URL,
    placeholderImageURL: "https://d3rmqduwxo9kfv.cloudfront.net/fit-in/400x400/product/3799a630-0184-4323-a45a-e972657ad78e.png",
  },
  getters: {
    isMarket: state => state.isMarket,
    fromLogin: state => state.fromLogin,
    socketOrder: state => state.lastSocketOrder,
    socketAlert: state => state.lastSocketAlert,
    socketAPIURL: state => state.socketAPIURL,
    middlewareURL: state => state.middlewareURL,
    googleMapsUrl: state => state.GOOGLE_MAPS_HOST,
    googleGeocodingPath: state => state.GOOGLE_GEOCODING_PATH,
    googleApiKey: state => state.GOOGLE_API_KEY,
    storeSelected: state => state.storeSelected,
    nodeMiddleware: state => state.nodeMiddleware,
    UrlMiddlewareGET: state => state.UrlMiddlewareGET,
    warehouseSelected: state => state.warehouseSelected,
    hasStoresChanged: state => state.hasStoresChanged,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    middlewarePromoPOST: state => state.middlewarePromoPOST,
  },
  mutations: {
    SET_FROM_LOGIN(state, val) {
      state.fromLogin = val
    },
    UPDATE_WAREHOUSE_SELECTED(state, val) {
      state.warehouseSelected = val
    },
    UPDATE_LAST_SOCKET_ORDER(state, val) {
      state.lastSocketOrder = val
    },
    UPDATE_LAST_SOCKET_ALERT(state, val) {
      state.lastSocketAlert = val
    },
    UPDATE_STORE_SELECTED(state, val) {
      if (val === null) {
        state.storeSelected = null;
      } else if (!isNaN(Number(val))) {
        state.storeSelected = Number(val);
      } else {
        state.storeSelected = 0;
      }
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_TOGGLE_MARKET(state, val) {
      state.isMarket = val
    },
    UPDATE_STORES_CHANGED(state, val) {
      state.hasStoresChanged = val
    }
  },
  actions: {
    async resetPassword({ commit }, data) {
      return await resetPassword(data)
    },
  },
}
