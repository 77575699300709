import { middlewareInstance } from "@api";
import store from "@/store";
import { getAuthToken } from "@/auth/utils";

export const bulkLoadProducts = async (data) => {
  const response = await middlewareInstance
    .post(store.state.app.middlewareURL, {
      path: "/product/bulk_load",
      body: JSON.stringify({
        tokenSesion: getAuthToken(),
        products: data
      }),
    })
    .then((res) => res.data);
  if (response.bOk) {
    return response;
  } else {
    throw Error(response.mensaje);
  }
}