export default {
  namespaced: true,
  state: {
    componentSidebarCreate: false,
    componentSidebarEdit: false,
  },
  getters: {
    componentSidebarCreate: state => state.componentSidebarCreate,
    componentSidebarEdit: state => state.componentSidebarEdit,
  },
  mutations: {
    UPDATE_COMPONENTSIDEBAR_CREATE(state, val) {
      state.componentSidebarCreate = val
    },
    UPDATE_COMPONENTSIDEBAR_EDIT(state, val) {
      state.componentSidebarEdit = val
    },
  },
  actions: {},
}
