export default [
    {
        path: '/subscriptions/plans',
        name: 'subscription-plans',
        component: () => import('@/views/subscription/plan/Index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'subscription',
        },
    },
    {
        path: '/subscriptions/options',
        name: 'subscription-options',
        component: () => import('@/views/subscription/option/Index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'subscription',
        },
    },
    {
        path: '/subscriptions/offers',
        name: 'subscription-offers',
        component: () => import('@/views/subscription/offer/Index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'subscription',
        },
    },
    {
        path: '/subscriptions/appplications',
        name: 'subscription-applications',
        component: () => import('@/views/subscription/application/Index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'subscription',
        },
    },
    {
        path: '/subscriptions/products',
        name: 'subscription-products',
        component: () => import('@/views/subscription/product/Index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'subscription',
        },
    }
]