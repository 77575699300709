import API from "@subscription"

export const getAllOptions = async () => {
    const response = await API.get('option').then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const storeOption = async (option) => {
    const response = await API.post('option', option).then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const updateOption = async (option) => {
    const response = await API.put(`option/${option.id}`, option)
    return response.status === 204
}