const state = () => ({
    products: [],
    count: 0,
})

const getters = {
    getUserData: _ => JSON.parse(localStorage.getItem('userData'))
}

const actions = {

}

const mutations = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}