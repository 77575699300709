import { middlewareInstance } from "@api";
import { getAuthToken } from "@/auth/utils";
import store from "@/store";

export default {
  async setBannerCarouselAction(context, payload) {
    let listAccionID = "";

    if (payload.listAccionID === '[]') {
      payload.listAccionID = "";
    }

    if (payload.accion === "CATEGORIA_OC") {
      if (typeof payload.listAccionID === 'object') {
        if (payload.listAccionID.every(i => i !== NaN)) {
          listAccionID = payload.listAccionID;
        }
      }
    }

    if (listAccionID !== "") {
      try {
        listAccionID = payload.listAccionID.join(",");
      } catch (e) {
        console.error("Error al parsear listAccionID:", e);
        listAccionID = "";
      }
      delete payload.accionID;
    }
    const body = {
      ...payload,
      listAccionID: listAccionID,
    };
    const request = {
      path: "/Administracion/SetUIItem",
      body: JSON.stringify({
        item: body,
        tokenSesion: getAuthToken(),
      }),
    };
    return await middlewareInstance
      .post(store.state.app.middlewareURL, request)
      .then((res) => res.data);
  },
  async deleteItemAction(context, payload) {
    const request = {
      path: "/Administracion/DeleteUIItem",
      body: JSON.stringify({
        tokenSesion: getAuthToken(),
        item: payload,
      }),
    };
    return await middlewareInstance
      .post(store.state.app.middlewareURL, request)
      .then((res) => res.data);
  },
  async updateElementOrderItemAction(context, payload) {
    const request = {
      path: "/Administracion/UpdateElementOrderItem",
      body: JSON.stringify({
        tokenSesion: getAuthToken(),
        item: payload,
      }),
    };
    return await middlewareInstance
      .post(store.state.app.middlewareURL, request)
      .then((res) => res.data);
  },
  async createElementAction(context, payload) {
    const request = {
      path: "/Administracion/CreateUIElement",
      body: JSON.stringify({
        tokenSesion: getAuthToken(),
        element: payload,
      }),
    };
    return await middlewareInstance
      .post(store.state.app.middlewareURL, request)
      .then((res) => res.data);
  },
  async createItemAction(context, payload) {
    const request = {
      path: "/Administracion/CreateUIItem",
      body: JSON.stringify({
        tokenSesion: getAuthToken(),
        item: payload,
      }),
    };
    return await middlewareInstance
      .post(store.state.app.middlewareURL, request)
      .then((res) => res.data);
  },
  async getUIElementsAction(context) {
    const request = {
      path: "/Administracion/GetUIElements",
      body: JSON.stringify({
        tokenSesion: getAuthToken(),
      }),
    };
    const data = await middlewareInstance
      .post(store.state.app.middlewareURL, request)
      .then((res) => res.data);
    context.commit("elementsCommit", data);
  },
};
