import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import apps from "./routes/apps";
import pages from "./routes/pages";
import subscriptions from "./routes/subscriptions";

import { isUserLoggedIn } from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...apps,
    ...pages,
    ...subscriptions,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

const publicRoutes = ["auth-login", "reset_password"];

router.beforeEach((to, from, next) => {
  if (publicRoutes.includes(to.name)) {
    next();
  } else {
    const isLoggedIn = isUserLoggedIn();
    if (isLoggedIn) {
      next();
    } else {
      next({ name: "auth-login" });
    }
  }
});

export default router;
