import axios from "@axios";

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      return userData && userData.extras
        ? userData.extras.eCommerceCartItemsCount
        : 0;
    })(),
    productDetails: [],
    products: [],
    paymentTotal: [],
    costoEnvio: 0,
    esEnvioGratis: false
  },
  getters: {
    getCartProductsCount: (state) => state.products.length,
    getCartItemsCount: (state) =>
      state.products.reduce((sum, item) => sum + item.qty, 0),
    getCartItems: (state) => state.products,
    getTotalAmount: (state) =>
      state.products
        .reduce((sum, item) => sum + item.precioiva * item.qty, 0)
        .toFixed(2),
    getPaymentTotal: (state) => state.paymentTotal,
    getCostoEnvio: (state) => state.costoEnvio,
    getEsEnvioGratis: (state) => state.esEnvioGratis,
    getTotalOrder: (state) => {
      const total = state.paymentTotal.total + state.costoEnvio;
      return isNaN(total) ? 0 : total.toFixed(2);
    }
  },
  mutations: {
    ADD_COSTO_ENVIO(state, item) {
      state.costoEnvio = item;
    },
    ADD_PAYMENT_TOTAL(state, items) {
      state.paymentTotal = items;
    },
    REMOVE_PRODUCT_CART(state, productId) {
      const itemIndex = state.products.findIndex((p) => p.id === productId);
      state.products.splice(itemIndex, 1);
    },
    REMOVE_ALL_CART_ITEMS(state) {
      state.products = [];
    },
    UPDATE_CART_ITEMS_COUNT(state) {
      state.cartItemsCount = state.products.reduce(
        (sum, item) => sum + item.qty,
        0
      );
    },
    ADD_ITEM_CART(state, item) {
      // push or replace item if exists
      const index = state.products.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        // get existing item
        const existingItem = state.products[index];
        // update qty
        if( existingItem.stock >  existingItem.qty)
          existingItem.qty += 1;
        // replace item
        state.products.splice(index, 1, item);
        console.log('q1')
      } else {
        item.qty = 1;
        state.products.push(item);
        console.log('q2')
      }
    },
    GET_ITEMS_CART(state) {
      return state.products;
    },
    UPDATE_PRODUCT_QUANTITY_IN_CART(state, { productId, qty }) {
      const index = state.products.findIndex((i) => i.id === productId);
      if (index !== -1) {
        const existingItem = state.products[index];
        if (qty > 0) {
          existingItem.qty = qty;
          state.products.splice(index, 1, existingItem);
        } else {
          state.products.splice(index, 1);
        }
      }
    },
    UPDATE_PRODUCT_DETAILS(state, items) {
      state.productDetails = items;
    },
    UPDATE_CART_ITEMS(state, items) {
      state.products = items;
    },
    UPDATE_ES_ENVIO_GRATIS(state) {
      state.esEnvioGratis = !state.esEnvioGratis;
    }
  },
  actions: {
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/ecommerce/products", { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/ecommerce/wishlist")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCartProducts({ state }) {
      return new Promise((resolve, reject) => {
        const products = state.products;
        resolve({ products });
      });
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/ecommerce/wishlist", { productId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addProductInCart(ctx, { productId }) {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post('/apps/ecommerce/cart', { productId })
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
      //this.state.products.push(productId)
      return this.products;
    },
    removeProductFromCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${productId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProductQty(ctx, { productId, qty }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/apps/ecommerce/cart/${productId}`, { qty })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePaymenTotal(ctx) {
      const data = localStorage.getItem("tienda");
      if (!data) return;

      const { client } = JSON.parse(data);
      const payload = {
        idCliente: client.cliente,
        productos: ctx.state.products,
        esEnvioGratis: ctx.state.esEnvioGratis
      };
      axios
        .post(ctx.rootState.app.middlewareURL, {
          path: "/Customer/ConsultarProductosCarritoLite",
          body: JSON.stringify(payload)
        })
        .then((res) => {
          if (res.data.bOk) {
            const { saleOutputs, paymentTotal } = res.data.totales;
            const { productos } = res.data;
            ctx.commit("ADD_PAYMENT_TOTAL", paymentTotal); // addPaymentTotal(paymentTotal)
            ctx.commit("ADD_COSTO_ENVIO", res.data.costoEnvio); // this.addCostoEnvio(res.data.costoEnvio)
            const productDetails = saleOutputs.map(
              ({
                productoID,
                subTotal,
                subTotalWithDiscountPercentage,
                total,
                totalWithDiscountPercentage,
                iva,
                ivaValue,
                ivaWithDiscountPercentage,
                descuento,
                totalPercent
              }) => {
                const {
                  nombre,
                  token,
                  sku,
                  tipo,
                  precio,
                  qty
                } = productos.find((p) => p.id === productoID);
                const product = {
                  id: productoID,
                  name: nombre,
                  type: tipo,
                  tokenProvider: token,
                  sku: sku,
                  unitPrice: precio,
                  quantity: qty,
                  subtotal: subTotalWithDiscountPercentage,
                  subtotalWithoutDiscount: subTotal,
                  total: totalWithDiscountPercentage,
                  totalWithoutDiscount: total,
                  taxRate: iva,
                  tax: ivaWithDiscountPercentage,
                  taxWithoutDiscount: ivaValue,
                  discount: descuento,
                  discountRate: totalPercent
                };
                return product;
              }
            );
            ctx.commit("UPDATE_PRODUCT_DETAILS", productDetails);
          }
        })
        .catch((e) => console.error(e));
    }
  }
};
