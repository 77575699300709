var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "terms-modal",
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-header": "",
      "ok-only": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var ok = _ref.ok;
        return [_c('b-button', {
          staticClass: "btn btn-primary btn-block text-center mx-5 mb-1 disabled-opacity",
          attrs: {
            "size": "sm",
            "variant": "success",
            "disabled": !_vm.aceptarTerminos
          },
          on: {
            "click": function click($event) {
              return _vm.setTerminos();
            }
          }
        }, [_vm._v(" Aceptar ")])];
      }
    }])
  }, [_c('p', {
    staticClass: "mt-2 font-weight-bolder text-center"
  }, [_vm._v("Términos y condiciones")]), _c('p', {
    staticClass: "mb-2 mx-2 text-justify"
  }, [_vm._v(" En SuperEasy, tratamos tus datos con el fin de gestionar nuestra relación como cliente. Tienes derecho a acceder, rectificar y actualizar tus datos, así como a solicitar su supresión, limitación o eliminación. También tienes derecho a la portabilidad de tus datos cuando proceda y a consultar nuestro Registro Nacional de Protección de Datos Personales. Si quieres ejercer estos derechos, envía un correo a administracion@supereasy.ec ")]), _c('b-form-group', [_c('div', {
    staticClass: "mx-2"
  }, [_c('b-form-checkbox', {
    staticClass: "font-weight-bold mb-1",
    attrs: {
      "id": "checkbox-2",
      "name": "checkbox-2"
    },
    model: {
      value: _vm.aceptarCookies,
      callback: function callback($$v) {
        _vm.aceptarCookies = $$v;
      },
      expression: "aceptarCookies"
    }
  }, [_vm._v(" Acepto el envío de comunicaciones comerciales personalizadas, basadas en sus gustos, preferencias y consumos, sobre los productos y servicios de SUPEREASY por cualquier medio. ")]), _c('b-form-checkbox', {
    staticClass: "font-weight-bold",
    attrs: {
      "id": "checkbox-1",
      "name": "checkbox-1"
    },
    model: {
      value: _vm.aceptarTerminos,
      callback: function callback($$v) {
        _vm.aceptarTerminos = $$v;
      },
      expression: "aceptarTerminos"
    }
  }, [_vm._v(" He leído y acepto las condiciones generales, "), _c('a', {
    staticClass: "text-dark font-weight-bolder",
    attrs: {
      "href": "https://supereasy.ec/terminos/"
    }
  }, [_c('u', [_vm._v("Términos y Condiciones")])]), _vm._v(" y "), _c('a', {
    staticClass: "text-dark font-weight-bolder",
    attrs: {
      "href": "https://supereasy.ec/privacidad/"
    }
  }, [_c('u', [_vm._v("Políticas de Privacidad")])]), _vm._v(". ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }