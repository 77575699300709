import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import appEcommerce from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import subscription from './subscription'
import product from './product'
import contenido from './contenido'
import ivaFeriado from './iva-feriado'
import formSidebar from './form-sidebar'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    subscription,
    product,
    formSidebar,
    ivaFeriado,
    contenido,
    appEcommerce,
    user,
  },
  strict: process.env.NODE_ENV !== 'production',//process.env.DEV,
})
