import axios from "axios"

const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
}

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    responseType: "json",
    headers: headers
})

export default instance