import { getAllApplications, storeApplication, updateApplication } from '@subscriptionApplication'
import { getAllUserGroupTypePlan } from '@subscriptionUserGroupTypePlan'
import { storeOffer, getAllOffers } from '@subscriptionOffer'
import { storeOption, updateOption, getAllOptions } from '@subscriptionOption'
import { storePlan, updatePlan, getAllPlans, getPlansByApplication } from '@subscriptionPlan'
import { getProductsByApplication, storeProductsByApplication, deleteProductsByApplication } from '@subscriptionProduct'

export default {
    namespaced: true,
    actions: {
        async getAllApplications({commit}) {
            return await getAllApplications()
        },
        async storeApplication({commit}, application) {
            return await storeApplication(application)
        },
        async updateApplication({commit}, application) {
            return await updateApplication(application)
        },
        async getAllUserGroupTypePlan({commit}) {
            return await getAllUserGroupTypePlan()
        },
        async storeOffer({commit}, offer) {
            return await storeOffer(offer)
        },
        async getAllOffers({commit}) {
            return await getAllOffers()
        },
        async storeOption({commit}, option) {
            return await storeOption(option)
        },
        async updateOption({commit}, option) {
            return await updateOption(option)
        },
        async getAllOptions({commit}) {
            return await getAllOptions()
        },
        async storePlan({commit}, plan) {
            return await storePlan(plan)
        },
        async updatePlan({commit}, plan) {
            return await updatePlan(plan)
        },
        async getAllPlans({commit}) {
            return await getAllPlans()
        },
        async getPlansByApplication({commit}, applicationId) {
            return await getPlansByApplication(applicationId)
        },
        async getProductsByApplication({commit}, applicationId) {
            return await getProductsByApplication(applicationId)
        },
        async storeProductsByApplication({commit}, data) {
            return await storeProductsByApplication(data)
        },
        async deleteProductsByApplication({commit}, data) {
            return await deleteProductsByApplication(data)
        },
    }
}