import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};

export const middlewareInstance = axios.create({
  responseType: "json",
  headers: headers,
  validateStatus: (status) => {
    return status >= 200 && status < 500
  },
});

