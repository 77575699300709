import { submitIvaFeriadoEmpresa, getIvaList, getIvaEmpresaFeriadoList } from '@api/configuracion/ivaFeriado'

export default {
  namespaced: true,
  state: {
    ivaList: [],
    ivaEmpresaFeriadoList: []
  },
  getters: {
    ivaListState: state => state.ivaList,
    ivaEmpresaFeriadoState: state => state.ivaEmpresaFeriadoList,
  },
  actions: {
    async submitIvaFeriadoEmpresaAction({ commit }, data) {
      return await submitIvaFeriadoEmpresa(data);
    },
    async getIvaListAction({ commit }) {
      const data = await getIvaList();
      commit('ivaListCommit', data);
    },
    async getIvaEmpresaFeriadoAction({ commit }) {
      const data = await getIvaEmpresaFeriadoList();
      commit('ivaEmpresaFeriadoListCommit', data);
    }
  },
  mutations: {
    ivaListCommit(state, data) {
      state.ivaList = data;
    },
    ivaEmpresaFeriadoListCommit(state, data) {
      state.ivaEmpresaFeriadoList = data;
    }
  }
};
