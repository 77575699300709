export const isUserLoggedIn = () => !!getUserData();

export const setDefaultStore = (storeID) => {
    const userData = getUserData();
    userData.empresaAsociada = storeID;
    localStorage.setItem("userData", JSON.stringify(userData));

    window.dispatchEvent(new CustomEvent('empresaAsociadaID-localstorage-changed', {
        detail: {
            storage: JSON.parse(localStorage.getItem('userData')).empresaAsociada
        }
    }));
}

export const setDefaultWarehouse = (warehouseID) => {
    const userData = getUserData();
    userData.bodega = warehouseID;
    localStorage.setItem("userData", JSON.stringify(userData));
}

export const getUserID = () => getUserData().userID;

export const isSuperUser = () => getRoleUser() === "SA";

export const isSupervisor = () => getRoleUser() === "SU";

export const isCallCenter = () => getRoleUser() === "CC";

export const isManager = () => getRoleUser() === "GE";

export const isPicker = () => getRoleUser() === 'PK';

export const isAdministrator = () => getRoleUser() === "AD";

export const isCajero = () => getRoleUser() === "CA";

export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export const getRoleUser = () => getUserData()?.rol;

export const getRoleName = () => roleName();

export const isRoleEats = () => !isRoleMarket();

export const isRoleMarket = () => roleMarket.includes(getRoleUser());

export const getAuthToken = () => getUserData().token;

export const getUserFullName = () => getUserData().nombreUsuario;

export const getDefaultStore = () => getUserData().empresaAsociada;

export const getDefaultWarehouse = () => getUserData().bodega;


export const adminPages = ['dashboard', 'ordenes', 'orden-detalle', 'kanban', 'usuarios', 'cargar-productos', 'mi-menu', 'store', 'checker-store-edit', 'reportes', 'test'];
export const cajeroPages = ['kanban', 'ordenes', 'mi-menu', 'cargar-productos', 'orden-detalle', 'store', 'checker-store-edit', 'test'];
export const callCenterPages = ['ordenes', 'mi-menu', 'cargar-productos', 'orden-detalle', 'store', 'tienda', 'clientes'];
export const publicPages = ['misc-not-authorized', 'auth-login'];
export const gerentePages = ['dashboard', 'ordenes', 'orden-detalle', 'kanban', 'mi-menu', 'cargar-productos', 'usuarios', 'store', 'checker-store-edit', 'reportes'];
export const sGerentePages = ['dashboard', 'kanban', 'store', 'mi-menu', 'cargar-productos', 'usuarios', 'reportes', 'orden-detalle'];
export const supervisorPages = ['dashboard', 'ordenes', 'easycreditos', 'cargar-productos', 'mi-menu', 'contenido', 'picking', 'sampling', 'usuarios', 'reportes', 'subscription', 'orden-detalle', 'picking-orden-detalle', 'ECommerceCheckout'];
// export const callcenterPages = ['call-center', 'clientes', 'promociones', 'easycreditos', 'market','orden-detalle', 'ordenes', 'mi-menu', 'cargar-productos', 'orden-detalle', 'store'];
export const despachadorPages = ['kanban'];
export const shopper = ['clientes', 'tienda', 'ordenes', 'tienda-cliente', 'ECommerceCheckout', 'orden-detalle'];
export const picker = ['ordenes', 'orden-detalle', 'picking', 'picking-orden-detalle']

const roleMarket = ['CA', 'SU', 'PK'];

const roleName = () => {
    const roleUser = getRoleUser();
    switch (roleUser) {
        case "SA":
            return "Super Admin";
        case "AD":
            return "Administrador";
        case "CA":
            return "Cajero";
        case "CC":
            return "Call Center";
        case "GE":
            return "Gerente";
        case "SG":
            return "Super Gerente";
        case "SU":
            return "Supervisor";
        case "DE":
            return "Despachador";
        case "C":
            return "Cliente";
        case "PS":
            return "Shopper";
        case "PK":
            return "picker";
        default:
            return "Rol No Definido";
    }
}

export const canUser = (routeName) => {
    const pagesAllowed = rolePages();
    if (pagesAllowed)
        return pagesAllowed.includes(routeName);
    else return true;
}

export const rolePages = () => {
    const roleUser = getRoleUser();
    switch (roleUser) {
        case "SA": //SUPER ADMIN
            return false;
        case "AD": //ADMIN
            return adminPages;
        case "CA": //CAJERO
            return cajeroPages;
        case "CC": //CALL CENTER
            return callCenterPages;// cajeroPages;
        case "GE": //GERENTE
            return gerentePages;
        case "SG": //SUPER GERENTE
            return gerentePages;
        case "SU": //SUPERVISOR
            return supervisorPages;
        case "DE": //DESPACHADOR
            return despachadorPages;
        case "C": //CLIENTE
            return [];
        case "PS":
            return shopper;
        case "PK":
            return picker;
        default:  //ROLES INDEFINIDOS
            return [];
    }
}

export const getSpecialViewsRol = (view) => {
    const roleUser = getRoleUser();
    switch (view) {
        case "ActivateShop":
            return ["CA", "AD", "GE"].includes(roleUser);
        default:
            return false;
    }
}
