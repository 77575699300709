export default [
    // *===============================================---*
    // *                    DASHBOARD
    // *===============================================---*

    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/analytics/Analytics.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'dashboard',
        },
    },

    // *===============================================---*
    // *                     ORDENES
    // *===============================================---*
    {
        path: '/ordenes',
        name: 'ordenes',
        component: () => import('@/views/ordenes/Listado.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'ordenes',
        },
    },
    {
        path: '/orden/:id',
        name: 'orden-detalle',
        component: () => import('@/views/ordenes/Detalle.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
            navActiveLink: 'ordenes',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/ordenes-kanban',
        name: 'kanban',
        component: () => import('@/views/ordenes/Kanban.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                   MI TIENDA
    // *===============================================---*
    {
        path: '/store',
        name: 'store',
        component: () => import('@/views/store/Store.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'store',
            layout: 'vertical',
        },
    },
    {
        path: '/store/editar/:id',
        name: 'store-edit',
        component: () => import('@/views/store/CreateEdit.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'store',
            layout: 'vertical',
        },
    },
    {
        path: '/store/cajero/editar/:id',
        name: 'checker-store-edit',
        component: () => import('@/views/store/BasicEditChecker.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'store',
            layout: 'vertical',
        },
    },
    {
        path: '/store/crear',
        name: 'store-create',
        component: () => import('@/views/store/CreateEdit.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'store',
            layout: 'vertical',
        },
    },

    // *===============================================---*
    // *               MENU DE LA TIENDA
    // *===============================================---*
    {
        path: '/mi-menu',
        name: 'mi-menu',
        component: () => import('@/views/mi-menu/MiMenu.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'mi-menu',
            contentClass: 'ecommerce-application',
            layout: 'vertical',
        },
    },

    // *===============================================---*
    // *             SUBIR PRODUCTOS EATS
    // *===============================================---*
    {
        path: '/cargar-productos',
        name: 'cargar-productos',
        component: () => import('@/views/cargar-productos/SubirArchivo.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *            ADMINISTRADOR DE CONTENIDO
    // *===============================================---*
    {
        path: '/home',
        name: 'contenido',
        component: () => import('@/views/contenido/Contenido.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    {
        path: '/menu',
        name: 'menu',
        component: () => import('@/views/contenido/Menu.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    {
        path: '/categorias',
        name: 'categorias',
        component: () => import('@/views/contenido/Categorias.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    {
        path: '/store-categories',
        name: 'store-categories',
        component: () => import('@/views/contenido/Store-Categories.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },


    // *===============================================---*
    // *                     PICKING
    // *===============================================---*
    {
        path: '/picking',
        name: 'picking',
        component: () => import('@/views/picking/Listado.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'picking',
            layout: 'vertical',
        },
    },

    {
        path: '/picking/orden/:id',
        name: 'picking-orden-detalle',
        component: () => import('@/views/picking/Detalle.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'picking',
            contentClass: 'ecommerce-application',
            layout: 'vertical',
        },
    },

    // *===============================================---*
    // *                     SAMPLING
    // *===============================================---*
    {
        path: '/sampling',
        name: 'sampling',
        component: () => import('@/views/sampling/Listado.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    {
        path: '/sampling/productos',
        name: 'sampling-productos',
        component: () => import('@/views/sampling/Productos.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                     MARCAS
    // *===============================================---*
    {
        path: '/marcas',
        name: 'marcas',
        component: () => import('@/views/marcas/Marcas.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                   CALL CENTER
    // *===============================================---*
    {
        path: '/call-center',
        name: 'call-center',
        component: () => import('@/views/call-center/CallCenter.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *             NOVEDADES INFORMATIVAS
    // *===============================================---*
    {
        path: '/novedades-informativas',
        name: 'novedades-informativas',
        component: () => import('@/views/novedades-informativas/NovedadesInformativas.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                   USUARIOS
    // *===============================================---*
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('@/views/usuarios/Usuarios.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
        path: '/usuario/recuperar-password',
        name: 'reset_password',
        component: () => import('@/views/usuarios/ResetPassword.vue'),
        meta: {
            requiresAuth: false,
            layout: 'full',
            resource: 'Auth',
        }
    },

    // *===============================================---*
    // *                  REPORTES
    // *===============================================---*
    {
        path: '/reportes',
        name: 'reportes',
        component: () => import('@/views/reportes/Reportes.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                  CLIENTES
    // *===============================================---*
    {
        path: '/clientes',
        name: 'clientes',
        component: () => import('@/views/clientes/Clientes.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
        path: '/cliente/:id',
        name: 'clientes-edit',
        component: () => import('@/views/clientes/Editar.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'clientes',
            layout: 'vertical',
        },
    },

    // *===============================================---*
    // *                  EASYCREDITOS
    // *===============================================---*
    {
        path: '/easycreditos',
        name: 'easycreditos',
        component: () => import('@/views/easycreditos/Listado.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'easycreditos',
            layout: 'vertical',
        },
    },
    {
        path: '/easycredito/',
        name: 'easycredito-detalle',
        component: () => import('@/views/easycreditos/CrearEditar.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'easycreditos',
            contentClass: 'ecommerce-application',
            layout: 'vertical',
        },
    },
    // *===============================================---*
    // *                    BODEGAS
    // *===============================================---*
    {
        path: '/bodegas',
        name: 'bodegas',
        component: () => import('@/views/bodegas/Listado.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
      path: '/bodega/nueva',
      name: 'bodega-nueva',
      component: () => import('@/views/bodegas/CrearBodega.vue'),
      meta: {
          requiresAuth: true,
          navActiveLink: 'bodegas',
          layout: 'vertical',
      },
    },
    {
        path: '/bodega/:id',
        name: 'bodega-detalle',
        component: () => import('@/views/bodegas/EditarBodega.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'bodegas',
            layout: 'vertical',
        },
    },

    // *===============================================---*
    // *                PRODUCTOS MARKET
    // *===============================================---*
    {
        path: '/market/productos',
        name: 'productosmarket',
        component: () => import('@/views/market/Productos.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'productosmarket',
            layout: 'vertical',
        },
    },
    {
        path: '/market/producto/:id',
        name: 'producto-edit',
        component: () => import('@/views/market/Producto.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'productosmarket',
            layout: 'vertical',
        },
    },
    {
        path: '/market/producto/productos-compuestos',
        name: 'productos-compuestos',
        component: () => import('@/views/market/ProductosCompuestos.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'productosmarket',
            layout: 'vertical',
        },
    },
    {
        path: '/market/producto/ordenar',
        name: 'producto-ordenar',
        component: () => import('@/views/market/Ordenar.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        },
    },
    {
        path: '/market/actualizacion',
        name: 'actualizacion-masiva-productos',
        component: () => import('@/views/market/Product/BulkLoad.vue'),
        meta: {
            requiresAuth: true,
            // navActiveLink: 'actualizacion-masiva-productos',
            layout: 'vertical',
        },
    },
    // *===============================================---*
    // *                  PROMOS
    // *===============================================---*
    {
        path: '/promocodes',
        name: 'promocodes',
        component: () => import('@/views/promos/Promocodes.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
        path: '/promocode/:id',
        name: 'promocode',
        component: () => import('@/views/promos/EditarPromo.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'promocodes',
            layout: 'vertical',
        },
    },
    {
        path: '/promotarjetas',
        name: 'promotarjetas',
        component: () => import('@/views/promos/Promotarjetas.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
        path: '/promotarjeta/:id',
        name: 'promotarjeta-edit',
        component: () => import('@/views/promos/EditarPromo.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'promotarjetas',
            layout: 'vertical',
        },
    },
    {
        path: '/bines',
        name: 'bines',
        component: () => import('@/views/promos/Bines.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'bines',
            layout: 'vertical',
        },
    },

    // *===============================================---*
    // *              NOTIFICACIONES PUSH
    // *===============================================---*
    {
        path: '/notificaciones',
        name: 'notificaciones-push',
        component: () => import('@/views/notificaciones-push/Listado.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                BANNER INVASIVO
    // *===============================================---*
    {
        path: '/invasivo',
        name: 'banner-invasivo',
        component: () => import('@/views/banner-invasivo/Listado.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },

    // *===============================================---*
    // *                  CACHE MODULE
    // *===============================================---*
    {
        path: '/cache',
        name: 'cache-module',
        component: () => import('@/views/cache/Cache.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
      path: '/iva-feriado',
      name: 'iva-feriado',
      component: () => import('@/views/iva-feriado/IvaFeriado.vue'),
      meta: {
          requiresAuth: true,
          layout: 'vertical',
      }
    },
    // *===============================================---*
    // *                  shopper
    // *===============================================---*
    {
        path: '/tienda',
        name: 'tienda',
        component: () => import('@/views/tienda/Index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    },
    {
        path: '/tienda-cliente',
        name: 'tienda-cliente',
        component: () => import('@/views/tienda/TiendaCliente.vue'),
        meta: {
            requiresAuth: true,
            navActiveLink: 'tienda-cliente',
            layout: 'vertical',
        }
    },
    //tienda-cliente
    {
        path: '/apps-e-commerce-checkout',
        name: 'apps-e-commerce-checkout',
        component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
        meta: {
            requiresAuth: true,
            layout: 'vertical',
        }
    }
]