// import axios from "axios";
import store from "@/store";
import { middlewareInstance } from "@api";
import { getAuthToken } from "@/auth/utils";

export const submitIvaFeriadoEmpresa = async (data) => {
  const response = await middlewareInstance
    .post(store.state.app.middlewareURL, {
      path: "/Configuracion/save/iva_empresa_feriado",
      body: JSON.stringify(data),
    })
    .then((res) => res.data);
  if (response.bOk) {
    return response;
  } else {
    throw Error(response.mensaje);
  }
};

export const getIvaList = async () => {
  try {
    const response = await middlewareInstance
      .post(store.state.app.middlewareURL, {
        path: "/Configuracion/iva",
        body: JSON.stringify({
          tokenSesion: getAuthToken(),
        }),
      })
      .then((res) => res.data);
    if (response.bOk) {
      return response.data;
    } else {
      throw Error(response.mensaje);
    }
  } catch (error) {
    throw Error(error);
  }
};

export const getIvaEmpresaFeriadoList = async () => {
  try {
    const response = await middlewareInstance
      .post(store.state.app.middlewareURL, {
        path: "/Configuracion/get/iva_empresa_feriado",
        body: JSON.stringify({
          tokenSesion: getAuthToken(),
        }),
      })
      .then((res) => res.data);
    if (response.bOk) {
      return response.data;
    } else {
      throw Error(response.mensaje);
    }
  } catch (error) {
    throw Error(error);
  }
};