import API from "@subscription"

export const getAllApplications = async () => {
    const response = await API.get('application').then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const storeApplication = async (application) => {
    const response = await API.post('application', application).then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const updateApplication = async (application) => {
    const response = await API.put(`application/${application.id}`, application)
    return response.status === 204
}