import API from "@subscription"

export const getAllOffers = async () => {
    const response = await API.get('offer').then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const storeOffer = async (offer) => {
    const response = await API.post('offer', offer).then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}