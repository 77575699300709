import API from "@subscription"

export const getProductsByApplication = async (applicationId) => {
    const response = await API.get(`product/application/${applicationId}`).then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const storeProductsByApplication = async (data) => {
    const response = await API.post('product/prime', data).then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const deleteProductsByApplication = async (data) => {
    const response = await API.delete(`product/prime/${data.ApplicationId}`, { data })
    return response.status === 204
}