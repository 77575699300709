import API from "@subscription"

export const getAllUserGroupTypePlan = async () => {
    const response = await API.get('usergrouptypeplan').then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}