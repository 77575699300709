<template>
  <b-modal
    id="terms-modal"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    ok-only
  >
    <p class="mt-2 font-weight-bolder text-center">Términos y condiciones</p>
    <p class="mb-2 mx-2 text-justify">
      En SuperEasy, tratamos tus datos con el fin de gestionar nuestra relación
      como cliente. Tienes derecho a acceder, rectificar y actualizar tus datos,
      así como a solicitar su supresión, limitación o eliminación. También
      tienes derecho a la portabilidad de tus datos cuando proceda y a consultar
      nuestro Registro Nacional de Protección de Datos Personales. Si quieres
      ejercer estos derechos, envía un correo a administracion@supereasy.ec
    </p>
    <b-form-group>
      <div class="mx-2">
        <b-form-checkbox
          v-model="aceptarCookies"
          id="checkbox-2"
          name="checkbox-2"
          class="font-weight-bold mb-1"
        >
          Acepto el envío de comunicaciones comerciales personalizadas, basadas
          en sus gustos, preferencias y consumos, sobre los productos y
          servicios de SUPEREASY por cualquier medio.
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-1"
          v-model="aceptarTerminos"
          name="checkbox-1"
          class="font-weight-bold"
        >
          He leído y acepto las condiciones generales,
          <a
            href="https://supereasy.ec/terminos/"
            class="text-dark font-weight-bolder"
            ><u>Términos y Condiciones</u></a
          >
          y
          <a
            href="https://supereasy.ec/privacidad/"
            class="text-dark font-weight-bolder"
            ><u>Políticas de Privacidad</u></a
          >.
        </b-form-checkbox>
      </div>
    </b-form-group>
    <template #modal-footer="{ ok }">
      <b-button
        size="sm"
        variant="success"
        :disabled="!aceptarTerminos"
        @click="setTerminos()"
        class="btn btn-primary btn-block text-center mx-5 mb-1 disabled-opacity"
      >
        Aceptar
      </b-button>
    </template>
  </b-modal>
</template>

<style>
.disabled-opacity:disabled {
  opacity: 0.5 !important;
}
</style>
<script>
import { BButton, BFormGroup, BFormCheckbox } from "bootstrap-vue";
import { getUserData, getAuthToken } from "@/auth/utils";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormCheckbox
  },
  name: "TermsModal",
  data() {
    return {
      aceptarTerminos: false,
      aceptarCookies: false,
      userData: getUserData()
    };
  },
  // on mounted show the modal
  mounted() {
    if (this.userData === null || this.userData === undefined) return;

    if (this.userData.userID !== null && !["SA"].includes(this.userData.rol)) {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/User/getTerminos",
          body: JSON.stringify({
            IDUsuario: this.userData.userID,
            tokenSesion: getAuthToken()
          })
        })
        .then((res) => {
          if (res.data.mensaje === "0") {
            this.$bvModal.show("terms-modal");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: {
    // on ok button click
    setTerminos() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/User/setTerminos",
          body: JSON.stringify({
            IDUsuario: this.userData.userID,
            tokenSesion: getAuthToken(),
            AceptoTerminos: this.aceptarTerminos,
            AceptoCookies: this.aceptarCookies
          })
        })
        .then((res) => {
          if (res.data.bOk) this.$bvModal.hide("terms-modal");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.terms-modal {
  max-width: 400px;
  padding: 20px;
}
.cancel-button {
  display: none;
}
</style>
