import API from "@subscription"

export const storePlan = async (plan) => {
    const response = await API.post('plan', plan).then(res => res.data)
    if(response.succeeded)
    {
        return {
            data: response.data,
            message: response.message
        }
    }
    else
    {
        throw Error(response.message)
    }
}

export const updatePlan = async (plan) => {
    const response = await API.put(`plan/${plan.id}`, plan)
    return response.status === 204
}

export const getAllPlans = async () => {
    const response = await API.get('plan').then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}

export const getPlansByApplication = async (applicationId) => {
    const response = await API.get(`plan/application/${applicationId}`).then(res => res.data)
    if(response.succeeded)
    {
        return response.data
    }
    else
    {
        throw Error(response.message)
    }
}